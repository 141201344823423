<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Account</h1>
          </div>
          <div class="page-content">
            <form v-on:submit.prevent="onSubmit">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">User Profile</h5>
                  <div class="card-text">
                    <div class="form-group row">
                      <label for="email" class="col-sm-3 col-form-label">Email</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        class="col-sm-9 form-control-plaintext"
                        readonly
                        :value="user.email"
                      />
                    </div>

                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="firstName">First Name</label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          class="form-control"
                          :class="{ 'is-invalid': errors.has('firstName') }"
                          autocomplete="off"
                          v-validate="'required|max:255'"
                          v-model="user.firstName"
                          data-vv-as="First Name"
                        />
                        <div class="invalid-feedback">{{ errors.first('firstName') }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="lastName">Last Name</label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          class="form-control"
                          :class="{ 'is-invalid': errors.has('lastName') }"
                          autocomplete="off"
                          v-validate="'required|max:255'"
                          v-model="user.lastName"
                          data-vv-as="Last Name"
                        />
                        <div class="invalid-feedback">{{ errors.first('lastName') }}</div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2">Email Opt Out</div>
                      <div class="col-sm-10">
                        <div class="form-check">
                          <input class="form-check-input position-static" type="checkbox" id="emailOptOut" v-model="user.emailOptOut" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-3">
                <div class="card-body">
                  <h5 class="card-title">Change Password</h5>
                  <div class="card-text">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="userPassword">Password</label>
                        <input
                          type="password"
                          id="userPassword"
                          name="userPassword"
                          class="form-control"
                          :class="{ 'is-invalid': errors.has('userPassword') }"
                          placeholder="Enter password"
                          autocomplete="off"
                          v-validate="'min:8|max:50'"
                          ref="userPassword"
                          v-model="user.password"
                          data-vv-as="Password"
                        />
                        <div class="invalid-feedback">{{ errors.first('userPassword') }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="userPasswordConfirm">Confirm Password</label>
                        <input
                          id="userPasswordConfirm"
                          name="userPasswordConfirm"
                          type="password"
                          class="form-control"
                          :class="{ 'is-invalid': errors.has('userPasswordConfirm') }"
                          placeholder="Enter Password Confirmation"
                          autocomplete="off"
                          v-validate="{ required: isPasswordConfirmRequired(), confirmed: 'userPassword' }"
                          data-vv-as="Password Confirm"
                        />
                        <div
                          class="invalid-feedback"
                        >'Confirm Password' and 'Password' do not match.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-3" v-if="user.companies.length">
                <div class="card-body">
                  <h5 class="card-title">My Companies</h5>
                  <hr />
                  <div class="card-text">
                    <div v-for="(company, index) in user.companies" :key="'user-company-' + index">

                      <div class="d-flex justify-content-between">
                        <h5 class="mb-3"> {{ company.name }} </h5>

                        <span v-if="$permissions().hasEditCompanyPermission">
                          <router-link :to="'/company/edit/' + company.id" title="Go to company profile" class="btn btn-success mb-2">
                            <i class="far fa-edit"></i>
                            Edit Company Profile
                          </router-link>
                        </span>
                      </div>

                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Zone</th>
                            <th scope="col">Membership Level</th>
                            <th scope="col">Expiry Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(membership, index) in company.memberships" :key="'company-membership-' + index">
                            <td>{{ membership.zone }}</td>
                            <td>{{ membership.role }}</td>
                            <td>{{ membership.expiryDate | datetime }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row d-flex justify-content-end mt-2">
                <menu class="d-flex align-items-center justify-content-end m-0">
                  <button type="submit" class="btn btn-success btn-lg ld-ext-right" :class="{ 'running' : isSaving }" :disabled="isSaving">
                    <i class="far fa-save"></i>
                    <span class="ml-2"> Update </span>
                    <div class="ld ld-ring ld-spin"></div>
                  </button>
                </menu>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppNavBar from "@/components/app-navbar.vue";
import Toast from "@/utils/toast";

export default {
  name: "account-settings",
  components: { "app-navbar": AppNavBar },
  data: function() {
    return {
      isSaving: false,
      isPageLoading: true,
      user: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        emailOptOut: false
      }
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"])
  },
  methods: {
    isPasswordConfirmRequired() {
      if (!this.$refs.userPassword) return false;
      return this.$refs.userPassword.value.length > 0;
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isSaving = true;
      this.updateUser(this.user)
        .then(user => {
          this.isSaving = false;
          this.setCurrentUser(user);
          Toast.success(this, "Successfully updated user!");
        })
        .catch(() => {
          this.isSaving = false;
          Toast.danger(this, "Oops! An error has occured.");
        });
    },
    ...mapActions(["fetchUser", "updateUser", "setCurrentUser"])
  },
  mounted() {
    let _user = this.getCurrentUser;

    this.fetchUser(_user.id).then(user => {
      let { id, firstName, lastName, email, companies, emailOptOut } = user;
      this.user = { id, firstName, lastName, email, companies, emailOptOut };
      this.isPageLoading = false;
    });
  }
};
</script>
